import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";

import Schlafgut from "../images/schlafgut.jpeg";

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Aktuelle Projekte / APPs / Webseiten" />
      <h1>Aktuelle Projekte und Links</h1>
      <br />
      <p>
        <img
          src="https://studio-wooom.de/static/studio-wooom-logo-b1fd3cf5c0f0bce97c882c8fb0b50706.png"
          width="100"
          alt="Meine Nanny"
        />
        &nbsp;&nbsp;&nbsp;&nbsp;
        <a
          href="https://studio-wooom.de/"
          rel="noreferrer"
          target="_blank"
          title="Studio WoooM!"
        >
          Studio WoooM!
        </a>
      </p>
      <h4>Das Studio WoooM!</h4>
      <p>
        Hier können Babys und kleine Kinder ihre ersten Drehungen üben, das
        Robben beginnen, das Krabbeln lernen, das Stehen auf zwei Beinen
        verinnerlichen und schließlich ihre ersten Schritte laufen.
        <br />
      </p>
      <hr />
      <br />
      <p>
        <a
          href="https://schlafgut-schlafberatung.de/"
          rel="noreferrer"
          target="_blank"
          title="schlafgut schlafberatung"
        >
          <img
            src="https://schlafgut-schlafberatung.de/static/schlafgut_schlafberatung_logo_2023_black-ee3ceb53b05c46ee48ac076e2c37900f.png"
            width="150"
            alt="schlafgut schlafberatung"
          />
        </a>
      </p>
      <h4>Schlafgut Schlafberatung</h4>
      <p>
        // Sandy Palmer <br />
        // Schlafgut Schlafberatung <br />
        // Schlafcoach für Babys und Kleinkinder
      </p>
      <hr />
      <h4>Apps (iOS, Android)</h4>
      <div className="row">
        <div className="col-sm-12" style={{ padding: 5 }}>
          <img
            src="https://michaelpalmer.de/sandy/app/babyschlaf-protokoll-app-logo-512.png"
            style={{
              width: 50,
              margin: 10,
            }}
            className="imagebordered"
            width="50"
            alt="Schlafgut Schlafberatung Schlafprotokoll"
          />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <a
            href="https://play.google.com/store/apps/details?id=de.schlafgut_schlafberatung"
            rel="noreferrer"
            target="_blank"
            title="Schlafgut Schlafprotokoll Google Play Store Android App"
          >
            <img
              src="https://michaelpalmer.de/sandy/app/store_android.png"
              height="40"
              alt="Google Play Store"
              border="0"
            />
          </a>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <a
            href="https://apps.apple.com/app/schlafgut-schlafprotokoll/id1605835781"
            rel="noreferrer"
            target="_blank"
            title="Schlafgut Schlafprotokoll Apple App Store iPhone App"
          >
            <img
              src="https://michaelpalmer.de/sandy/app/store_iphone.png"
              height="40"
              alt="Apple App Store iPhone App"
              border="0"
            />
          </a>
          <div className="linehr" />
          <p>
            Die APP für das persönliche <b>Schlafprotokoll</b> deines{" "}
            <b>Babys</b> oder Kleinkindes.
            <br />
            Weitere Infos dazu unter{" "}
            <a href="/apps/babyschlaf-protokoll-app" target="_self">
              babyschlaf-protokoll-app
            </a>
            .
            <br />
            <b>Du bist Schlafberater:in?</b> Und suchst eine APP für dich und
            deine Klienten? Dann schau hier:{" "}
            <a
              href="/apps/babyschlaf-protokoll-app-partnerschaft"
              target="_self"
            >
              babyschlaf-protokoll-app-partnerschaft
            </a>{" "}
            vorbei.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12" style={{ padding: 5 }}>
          <img
            src="https://play-lh.googleusercontent.com/mjuF_jHtzpTjhgJsTMABZixLOloaUu83Y0pTsoH7_0H4JhsHwYPWQCjRh0oKa1qSOrc"
            style={{
              width: 50,
              margin: 10,
            }}
            className="imagebordered"
            width="50"
            alt="Wiki Wars Reloaded"
          />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <a
            href="https://play.google.com/store/apps/details?id=de.palmomedia.wikiwars"
            rel="noreferrer"
            target="_blank"
            title="Wiki Wars Reloaded Google Play Store Android App"
          >
            <img
              src="https://michaelpalmer.de/sandy/app/store_android.png"
              height="40"
              alt="Google Play Store"
              border="0"
            />
          </a>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <a
            href="https://apps.apple.com/us/app/wikiwars-reloaded/id1613996417"
            rel="noreferrer"
            target="_blank"
            title="Wiki Wars Reloaded Apple App Store iPhone App"
          >
            <img
              src="https://michaelpalmer.de/sandy/app/store_iphone.png"
              height="40"
              alt="Apple App Store iPhone App"
              border="0"
            />
          </a>
          <div className="linehr" />
          <p>
            The run from start to a random destination article. Winner is who
            has less clicks in less time. Play against others - try now.
            Promopage:{" "}
            <a href="https://wikiwars.palmomedia.de/" target="_blank">
              Wiki Wars Reloaded
            </a>
          </p>
        </div>
      </div>
      <hr />
      <h4>Alexa Skills</h4>
      <div className="row">
        <div className="col-sm-12" style={{ padding: 5 }}>
          <img
            src="https://images-na.ssl-images-amazon.com/images/I/71Gns3Hy8DL.png"
            width="50"
            alt="Egal wie Witze"
          />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <a
            href="https://skills-store.amazon.de/deeplink/dp/B06XD5W7BS?deviceType=app"
            rel="noreferrer"
            target="_blank"
            title="Egal wie Witze"
          >
            Egal wie Witze
          </a>
        </div>
        <div className="col-sm-12" style={{ padding: 5 }}>
          <img
            src="https://images-na.ssl-images-amazon.com/images/I/51B5z1EZgYL.png"
            width="50"
            alt="Polizei Berlin"
          />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <a
            href="https://skills-store.amazon.de/deeplink/dp/B06XD5W7BS?deviceType=app"
            rel="noreferrer"
            target="_blank"
            title="Polizei Berlin"
          >
            Polizei Berlin
          </a>
        </div>
        <div className="col-sm-12" style={{ padding: 5 }}>
          <img
            src="https://images-na.ssl-images-amazon.com/images/I/411gjfvWqmL.png"
            width="50"
            alt="Planken im Unterarmstütz"
          />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <a
            href="https://skills-store.amazon.de/deeplink/dp/B06WGN9D71?deviceType=app"
            rel="noreferrer"
            target="_blank"
            title="Planken im Unterarmstütz"
          >
            Planken im Unterarmstütz
          </a>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
